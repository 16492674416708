import React, { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'
import axios from 'axios'
import { useLocation, useParams } from 'react-router-dom'

const LoanConfirmation = () => {
    const [loanAgreementUrl, setLoanAgreementUrl] = useState('')
    const [docId, setDocId] = useState('')
    const [isAgreed, setIsAgreed] = useState(false)
    const [loanAgreementSigned, setLoanAgreementSigned] = useState(false)

    const location = useLocation()
    const params = useParams()

    const { sessionId, totalAmount, venueName } = params

    const signLoanAgreement = async (agreementId) => {
        const data = {
            session_id: sessionId,
            doc_id: agreementId,
        }

        try {
            const response = await axios.post(
                'https://marley-backend.herokuapp.com/sign-loan-agreement',
                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (response.status === 200) {
                setLoanAgreementSigned(true)
            }
        } catch (error) {
            if (error.response) {
            } else if (error.request) {
            } else {
            }

            throw error
        }
    }

    const handleSubmit = async () => {
        if (isAgreed) {
            try {
                await signLoanAgreement(docId)
            } catch (error) {}
        } else {
        }
    }

    const handleCheckboxChange = (event) => {
        setIsAgreed(event.target.checked)
    }

    const handleClose = () => {
        window.close()
    }

    const fetchLoanAgreement = async () => {
        const { data, error } = await supabase
            .from('Applications')
            .select('loan_agreement_url, doc_id')
            .eq('session_id', sessionId)
            .single()

        if (error) {

        } else {
            setLoanAgreementUrl(data.loan_agreement_url)

            setDocId(data.doc_id)
        }
    }

    useEffect(() => {
        fetchLoanAgreement()
    }, [])

    return (
        <>
            {!loanAgreementSigned ? (
                <div className="min-h-screen text-white ">
                    <div className="flex flex-col text-white "></div>
                    <div className="flex flex-col items-center justify-center m-3 ">
                        <div className="w-full md:w-3/4 lg:w-1/2 text-center ">
                            <div className="bg-white text-black p-3 rounded-md shadow-md">
                                <h2 className="text-xl font-bold mb-3">
                                    Review and Confirm Loan Details
                                </h2>
                                <div>
                                    <div className="flex justify-center  lg:h-[350px] md:h-[300px] sm:h-[200px]">
                                        <object
                                            data={'https://gcyljkaelmuicdltfonh.supabase.co/storage/v1/object/sign/statements/sub_7jxnGSSYSNKKHYsYLS.pdf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJzdGF0ZW1lbnRzL3N1Yl83anhuR1NTWVNOS0tIWXNZTFMucGRmIiwiaWF0IjoxNzI1MTQwMTM0LCJleHAiOjE3NTY2NzYxMzR9.dBNdIyQr1X1iJVLA5NPukn8HBBuJFIXSzuRC0VqA9Nk&t=2024-08-31T21%3A35%3A34.052Z'}
                                            type="application/pdf"
                                        >
                                            <iframe
                                                src={`https://docs.google.com/viewer?url=${loanAgreementUrl}&embedded=true`}
                                                className="w-90 h-full"
                                                title="loan-agreement"
                                            ></iframe>
                                        </object>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white py-5 px-9 rounded-lg shadow-md text-black mt-3 text-left">
                                <input
                                    type="checkbox"
                                    name="isAgreed"
                                    checked={isAgreed}
                                    onChange={handleCheckboxChange}
                                    className="mr-4"
                                />

                                <label className="text-sm">
                                    I have carefully read and understood the
                                    entire loan agreement, and I hereby agree to
                                    its terms. Additionally, I consent to the{' '}
                                    <a href="https://www.pier-lending.com/legal">
                                        <strong>
                                            <u>e-sign disclosure</u>
                                        </strong>
                                    </a>{' '}
                                    policy, acknowledging that my electronic
                                    signature holds the same legal validity as a
                                    handwritten signature.
                                </label>
                                <div className="mt-5">
                                    <button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="text-center w-full py-2 px-4 bg-muted-purple text-white font-semibold rounded-lg shadow-md hover:bg-pink focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 mb-4"
                                    >
                                        Sign Loan Agreement
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="min-h-screen text-white">
                        <div className="flex flex-col text-white"></div>
                        <div className="flex flex-col items-center justify-center h-full">
                            <div className="w-full md:w-3/4 lg:w-1/2 text-center">
                                <div className="bg-white text-black p-2 rounded-md shadow-md p-5">
                                    <h2 className="text-2xl font-bold mb-2">
                                        Loan Confirmation
                                    </h2>

                                    <h1>
                                        <h1 className="font-semibold">
                                            Congratulations on financing with
                                            Marley!
                                        </h1>{' '}
                                        <h1></h1>
                                        Please check your email for additional
                                        details regarding your loan.
                                        <div className="mt-5">
                                            You may now close this window.
                                        </div>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default LoanConfirmation
