import React from "react";
import Application from "./pages/Application";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Approved from "./components/Approved";
import LoanConfirmation from "./components/LoanConfirmation";
import ApplicationPending from "./components/ApplicationPending";


function App() {
	return (
		<BrowserRouter>
			<nav className="w-full flex justify-between items-center py-4 px-6">
				<a href="/" className="text-4xl font-bold text-white">
					Marley
				</a>
			</nav>

			<Switch>
				<Route exact path="/new-application/:sessionId/:totalAmount/:venueName">
					<Application />
				</Route>
				<Route  path="/approved/:sessionId/:totalAmount/:venueName" component={Approved} />
				<Route
					
					path="/pending/:sessionId/:totalAmount/:venueName"
					component={ApplicationPending}
				/>
				<Route
					
					path="/pending"
					component={ApplicationPending}
				/>
				<Route
					
					path="/loanconfirmation/:sessionId/:totalAmount/:venueName"
					component={LoanConfirmation}
					key={Math.random()}
				/>
			</Switch>
		</BrowserRouter>
	);
}

export default App;
