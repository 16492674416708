import React, { useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useLocation, useParams } from "react-router-dom";


const LoanOfferDetailsCard = (props) => {
  const { loanInfo } = props;


  const location = useLocation();
    const params = useParams();
  
    const { sessionId, totalAmount, venueName } = params;

    
  const rate = loanInfo.apr / 100;

  function calculateMonthlyPaymentAndTotalInterest(loanAmount, loanTerm, interestRate) {
    // Convert interest rate from percentage to decimal
    interestRate = interestRate / 100;
    
    // Convert loan term from years to months
    loanTerm = loanTerm * 12;
    
    // Calculate monthly interest rate
    var monthlyInterestRate = interestRate / 12;
    
    // Calculate monthly payment
    var monthlyPayment = loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTerm) /
      (Math.pow(1 + monthlyInterestRate, loanTerm) - 1);
    
    // Calculate total payment over the loan term
    var totalPayment = monthlyPayment * loanTerm;
    
    // Calculate total interest paid
    var totalInterestPaid = totalPayment - loanAmount;
    
    // Round the values to 2 decimal places
    monthlyPayment = Math.round(monthlyPayment * 100) / 100;
    totalInterestPaid = Math.round(totalInterestPaid * 100) / 100;
    
    return { monthlyPayment, totalInterestPaid };
  }

const result = calculateMonthlyPaymentAndTotalInterest(totalAmount, 1, rate)
  const monthlyPayment = result.monthlyPayment;
  const interest = result.totalInterestPaid;
  const total = monthlyPayment *12 ;

  useEffect(() => {
   
   
    if (!isNaN(monthlyPayment) && sessionId) {
      const updateMonthlyPayment = async () => {
        await supabase
          .from('Applications')
          .update({ total_monthly_payment: formatter.format(monthlyPayment) })
          .eq('session_id', sessionId);
      };

      updateMonthlyPayment();
    }
  }, [monthlyPayment, sessionId]);
  if (isNaN(totalAmount) || isNaN(rate) || isNaN(loanInfo.termLength)) {
    return <div>Loading...</div>;
  }

  

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

 

  return (
    <div className={`relative w-full sm:w-90 bg-white shadow-md border border-gray-300 rounded-lg p-4 pb-5 ${props.className}`}>
      {/* <div className="absolute top-2 right-2 px-5 py-1 text-white text-sm font-bold bg-gray-600 rounded-lg">
      {loanInfo.termLength} Months
    </div> */}
    <h2 className="text-lg font-bold text-purple-400 mb-3">${formatter.format(monthlyPayment)}/month for <h2 className='text-black'>{loanInfo.termLength} Months</h2></h2>
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
      <div>
        <p className="font-semibold">APR</p>
        <p>{rate.toFixed(2)}%</p>
      </div>
      <div>
        <p className="font-semibold">Total Interest</p>
        <p>${formatter.format(interest)}</p>
      </div>
      <div>
        <p className="font-semibold">Total Amount</p>
        <p>${formatter.format(total)}</p>
      </div>
    </div>
  
  </div>
  );
};

export default LoanOfferDetailsCard;
