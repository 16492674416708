import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import BasicInfoForm from '../components/BasicInfoForm'
import { useLocation, useParams } from 'react-router-dom'
import { Rings } from 'react-loader-spinner'

const Spinner = () => {
    return (
        <div className="h-screen w-screen flex items-center justify-center">
            <Rings
                height="120"
                width="120"
                color="#ffffff"
                radius="6"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="rings-loading"
            />
        </div>
    )
}

const Application = () => {
  const [isApproved, setIsApproved] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const params = useParams();

  const { sessionId, totalAmount, venueName } = params;

  const handleSubmit = async (applicationStatus) => {

    setTimeout(() => { // Add a delay
      if (applicationStatus === "approved") {
        setIsApproved(true);
        setIsSubmitted(true);
        window.open(`/approved/${sessionId}/${totalAmount}/${venueName}`, "_self");
      } else {
        setIsApproved(true);
        window.open(`/approved/${sessionId}/${totalAmount}/${venueName}`, "_self");
      }
    }, 1000);  // 1 second delay, can be adjusted based on your preference
  };

  if (isLoading) {
    setTimeout(() => {
      
    },4500)
    setIsApproved(true);
        window.open(`/approved/${sessionId}/${totalAmount}/${venueName}`, "_self");
  }

  if (!isSubmitted) {
    return (
      <BasicInfoForm
        onSubmit={handleSubmit}
        setIsLoading={setIsLoading}

      />
    );
  }
};

export default Application;

